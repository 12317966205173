import request from "@/api/service";

const api = {
   createMarketing: '/event/smartMarketing/create', //创建营销
   getTemp: '/event/marketingTemp/getList', //营销模板选择
   getMemberCount: '/event/marketingTemp/getMemberCount', //获取当前选择模板满足人数
};


export function createMarketing(params) {
   return request({
      url: api.createMarketing,
      method: 'POST',
      data: params
   })
}

export function getTemp(params) {
   return request({
      url: api.getTemp,
      method: 'POST',
      data: params
   })
}

export function getMemberCount(params) {
   return request({
      url: api.getMemberCount,
      method: 'POST',
      data: params
   })
}





