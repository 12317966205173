<template>
  <section class="cont aKeyMarketing operate-box">
    <!-- 面包屑 -->
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>营销云</el-breadcrumb-item>
        <el-breadcrumb-item>智能营销</el-breadcrumb-item>
        <el-breadcrumb-item>一键营销</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
   <el-page-header @back="$router.push('/smart_marketing')" content="一键营销"></el-page-header>
    <el-row class="oper-content-box">
      <el-row>
        <el-form label-width="110px" @submit.native.prevent>
          <el-form-item label="选择模板" required>
            <div v-if="tempContent.isSelect">
              {{ tempContent.name }}
              <el-link type="primary" @click="dialogTempVisible = true">更换</el-link>
            </div>
            <el-button v-else type="primary" @click="handlerSelect()">选择模板</el-button>
          </el-form-item>
          <el-form-item label="选择渠道" required>
            <div v-if="tempContent.isSelect">
              <el-checkbox-group v-model="checkList">
                <el-checkbox :label="item" v-for="item in JSON.parse(tempContent.supportTypes)" :key="item"></el-checkbox>
              </el-checkbox-group>
            </div>
            <span v-else>未选择模板</span>
          </el-form-item>
          <el-form-item label="设置时间" required>
            <el-radio v-model="isTiming" label="NO">立即发送</el-radio>
            <el-radio v-model="isTiming" label="YES">定时发送</el-radio>
            <el-date-picker v-if="isTiming == 'YES'" v-model="startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item v-if="tempContent.isSelect">
            <div class="tips" v-loading="numberLoading" element-loading-text="当前所选模板满足人数正在计算中">
              <i class="el-icon-warning" style="margin-right:10px"></i>满足该条件的人数为{{number}}人，发送后最高发送{{number}}条信息
            </div>
          </el-form-item>
          <el-form-item style="margin-left: -70px">
            <el-button type="primary" :disabled="numberLoading" @click="sendTemp()">发送</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-row>

    <!-- 选择模板 -->
    <el-dialog title="选择模板" :visible.sync="dialogTempVisible" width="1300px" :before-close="close">
      <el-row :gutter="40">
        <el-col :span="13">
          <el-form>
            <el-form-item label="模板搜索">
              <el-input v-model="name" placeholder="请输入模板名" style="width: 300px; margin-right: 20px"></el-input>
              <el-button type="primary" @click="getMarketingTemplate()">搜索</el-button>
            </el-form-item>
            <el-form-item label="标签筛选">
              <el-checkbox @change="tagSelect(item.id, $event)" v-for="item in tagData" :key="item.id" :label="item.name" name="type"></el-checkbox>
            </el-form-item>
          </el-form>
          <el-row class="temp" :gutter="20">
            <el-col :span="8" v-for="item in tempList" :key="item.id" style="margin-top: 10px">
              <el-card class="box-card" style="cursor: pointer" :style="item.isSelect ? 'border: 1px solid #409eff' : ''" @click.native="selectTemp(item.id)">
                <div slot="header" class="clearfix">
                  <span class="title desc">{{ item.name }}</span>
                  <!-- <el-button style="float: right; padding: 3px 0;font-size:16px" type="text">
                        <el-link type="danger" :underline="false" v-if="item.isSelect">已选择</el-link>
                        <el-link type="primary" :underline="false" v-else>选择</el-link>
                     </el-button> -->
                </div>
                <div class="l-height-26">
                  <div class="text item desc">{{ item.description }}</div>
                  <div class="text item">适用渠道：</div>
                  <div class="text item desc">{{ supportTypeStr(item.supportTypes) }}</div>
                  <div class="text item">类型：{{ contentTypeFomart(item.contentType) }}</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10" class="select-temp">
          <p class="title">已选模板：{{ tempContent.name ? tempContent.name : '未选择模板' }}</p>
          <el-row v-if="tempContent != '' && tempContent.contentType != 'IMAGE'">
            <el-row class="temp-content-header">
              <el-col :span="8">内容：</el-col>
              <el-col :span="8" :offset="8" style="text-align: right"><el-link type="primary" :underline="false" @click="isEdit = false">编辑</el-link></el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="tempContent.content" :disabled="isEdit" resize="none"></el-input>
              </el-col>
            </el-row>
          </el-row>
          <el-row style="margin-top: 30px" v-if="tempContent != ''">
            <el-col v-if="tempContent.contentType == 'TEXT' && (tempContent.touchStrName || tempContent.propStrName)">
              <p style="font-size: 20px">目标人群</p>
              <br />
              <div v-html="tempContent.touchStrName" v-if="tempContent.touchStrName"></div>
              <div v-html="tempContent.propStrName" v-if="tempContent.propStrName"></div>
            </el-col>
            <el-col v-if="tempContent.contentType != 'TEXT'">
              <img width="200" height="200" :src="tempContent.image" alt="" />
              <el-link type="primary" style="margin-left: 30px" @click="visibleImage = true">重新上传</el-link>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="sureTemp">确 定</el-button>
      </div>
    </el-dialog>
    <Cropper :visible="visibleImage" @setImageInfo="getImageInfo" @closeUploadImg="closeUploadImg"></Cropper>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { getTagList } from '@/api/smtech/marketingTemplate.js'
  import Cropper from '@/components/local/Cropper'
  import { createMarketing, getTemp, getMemberCount } from '@/api/smtech/smartMarketing.js'
  import { dateFactory } from '@/common/js/common'
  export default {
    name: 'smartMarketing',
    components: { Cropper },
    data() {
      return {
        that: this,
        name: '', //模板名
        type: '',
        dialogTempVisible: false, //选择模板弹窗
        isSelect: false,
        tempList: [], //营销模板列表
        tempContent: '',
        isTiming: 'NO', //是否定时发送
        startTime: '', //定时发送时间
        checkList: [],
        visibleImage: false,
        isEdit: true,
        tagData: [],
        tagIds: [],
        number: 0,
        numberLoading: false
      }
    },
    computed: {
      ...mapState(['hotelInfo', 'dictData']),
      supportTypeStr() {
        return (data) => {
          data = JSON.parse(data)
          let str = ''
          for (let i in data) {
            str += data[i] + ' '
          }
          return str
        }
      },
      contentTypeFomart() {
        return (type) => {
          switch (type) {
            case 'TEXT':
              return '文字'
            case 'IMAGE':
              return '图片'
            case 'COMPOSITE':
              return '综合'
          }
        }
      },
    },
    watch: {
      hotelInfo: {
        handler(newVal, oldVal) {
          if (newVal.id !== oldVal.id && oldVal) {
            this.platform = this.$route.params.platform
            this.getMarketingTemplate()
            this.tagManagement()
          }
        },
      },
    },
    created() {
      this.getMarketingTemplate()
      this.tagManagement()
    },
    methods: {
      // 获取营销模板列表
      getMarketingTemplate() {
        let param = { name: this.name, tagIds: this.tagIds }
        getTemp(param).then((res) => {
          if (res.success) {
            this.tempList = res.records
            this.tempContent = ''
          }
        })
      },

      handlerSelect() {
        if (this.tempList.length == 0) {
          this.$message.error('暂无模板，请先添加模板')
        } else {
          this.dialogTempVisible = true
        }
      },

      // 获取标签列表
      tagManagement() {
        getTagList({ name: '' }, 10, 1).then((res) => {
          this.tagData = res.records
        })
      },

      tagSelect(id, e) {
        if (e) {
          if (!this.tagIds.includes(id)) {
            this.tagIds.push(id)
          }
        } else {
          this.tagIds = this.tagIds.filter((item) => item != id)
        }
        this.getMarketingTemplate()
      },

      // 选择模板
      selectTemp(id) {
        this.checkList = []
        this.isEdit = true
        this.tempContent = this.tempList.filter((item) => item.id == id)[0]
        let supportTypes = JSON.parse(this.tempContent.supportTypes)
        for (let i in supportTypes) {
          this.checkList.push(supportTypes[i])
        }

        this.isSelect = true
        for (let i in this.tempList) {
          if (this.tempList[i].id == id) {
            this.$set(this.tempList[i], 'isSelect', true)
          } else {
            this.$set(this.tempList[i], 'isSelect', false)
          }
        }
      },

      // 确定使用该模板
      sureTemp() {
        this.dialogTempVisible = false
        this.getMemberCount()
      },

      // 获取当前选择模板满足人数
      getMemberCount() {
        this.numberLoading = true
        getMemberCount({id: this.tempContent.id, companyId: this.hotelInfo.storeId}).then(res => {
          if (res.success) {
            this.number = res.records
            this.numberLoading = false
          }
        })
      },

      // 发送模板
      sendTemp() {
        if (!this.tempContent.isSelect) {
          this.$message.error('请先选择模板')
          return
        }
        if (!this.number) {
          this.$message.error('当前所选模板满足人数为0')
          return
        }
        this.$confirm(`是否确认发送？将给<span style="color:red">${this.number}人</span>发送该模板信息`, '发送确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }).then(() => {
          let { tagId, propStr, touchStr, propStrName, touchStrName } = this.tempContent
          let isDouyin, isNote, isApplet, isOfficialAccounts
          isDouyin = isNote = isApplet = isOfficialAccounts = 'NO'
          let param = { tagId, propStr, touchStr, propStrName, touchStrName, isDouyin, isNote, isApplet, isOfficialAccounts }
          param.hotelId = this.hotelInfo.id
          param.templateId = this.tempContent.id
          param.companyId = this.hotelInfo.storeId
          for (let i of this.checkList) {
            switch (i) {
              case '抖音':
                param.isDouyin = 'YES'
                break
              case '短信':
                param.isNote = 'YES'
                break
              case '小程序':
                param.isApplet = 'YES'
                break
              case '公众号':
                param.isOfficialAccounts = 'YES'
                break
            }
          }
          param.isTiming = this.isTiming
          if (param.isTiming == 'YES') {
            if (this.startTime == '') {
              this.$message.error('请选择需要定时发送的时间')
            } else {
              param.startTime = this.startTime
            }
          } else {
            param.startTime = dateFactory.dateFormat(true)
          }
          if (this.tempContent.contentType != 'TEXT') {
            param.imgPath = this.tempContent.image
            let first = param.imgPath.indexOf('/images/') + 8
            let last = param.imgPath.indexOf('?')
            param.fileName = param.imgPath.substring(first, last)
          }
          createMarketing(param).then((res) => {
            if (res.success) {
              this.$message.success('发送成功')
              this.$router.push('/smart_marketing')
            } else {
              this.$message.error(res.message)
            }
          })
        })
      },

      // 关闭上传图片会话框
      closeUploadImg() {
        this.visibleImage = false
      },
      // 获取上传图片信息
      getImageInfo({ url }) {
        this.visibleImage = false
        this.$set(this.tempContent, 'image', url)
      },
      // 关闭弹窗时触发
      closeDialog() {
        this.visibleImage = false
      },
      // 选择模板 取消按钮
      close() {
        this.dialogTempVisible = false
        this.tempContent = ''
        this.getMarketingTemplate()
        this.isSelect = false
      },
    }
  }
</script>

<style scoped lang="scss">
  .aKeyMarketing {
    /deep/ .el-card__header {
      padding: 10px 20px;
    }
    .tips {
      background:rgb(250, 236, 216);
      color:#E6A23C;
      padding:0 10px;
      margin-left:-80px;
      width:600px
    }
    .temp {
      display: flex;
      flex-wrap: wrap;
      .title {
        font-size: 16px;
        display: inline-block;
        width: 160px;
      }
      .desc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .select-temp {
      .title {
        font-size: 24px;
        font-weight: bold;
      }
      .temp-content-header {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .use-btn {
        margin-top: 30px;
      }
    }
  }
</style>
