import request from "@/api/service";

const api = {
   getPage: '/event/marketingTemp/getPage', //获取营销模板
   saveTemp: '/event/marketingTemp/saveTemp', //保存营销模板
   moveTemp: '/event/marketingTemp/move', //营销模板上下移
   updateStatus: '/event/marketingTemp/updateStatus', //修改模板状态
   deleteTemp: '/event/marketingTemp/delete', //删除模板
   getTag: '/event/marketingTag/getPage',   //获取标签
   addTag: '/event/marketingTag/saveTag',   //新增、修改标签
   deleteTag: '/event/marketingTag/delete'  //删除标签
};

export function getTempPage(limit,page, params) {
   return request({
      url: `${api.getPage}?limit=${limit}&page=${page}`,
      method: 'POST',
      data: params
   })
}

export function saveTemp(params) {
   return request({
      url: api.saveTemp,
      method: 'POST',
      data: params
   })
}

export function moveTemp(params) {
   return request({
      url: api.moveTemp,
      method: 'POST',
      data: params
   })
}

export function updateStatus(params) {
   return request({
      url: api.updateStatus,
      method: 'POST',
      data: params
   })
}

export function deleteTemp(id) {
   return request({
      url: api.deleteTemp,
      method: 'POST',
      data: {id}
   })
}

export function getTagList(param,limit,page) {
   return request({
      url: `${api.getTag}?limit=${limit}&page=${page}`,
      method: 'POST',
      data: param
   })
}

export function addTag(data) {
   return request({
      url: api.addTag,
      method: 'POST',
      data
   })
}

export function deleteTag(id) {
   return request({
      url: api.deleteTag,
      method: 'POST',
      data: {id}
   })
}





